export default {
  USER_REGISTER: "/users",
  USER_VERIFY: "/users/authentication/verifications",
  USER_LOGIN: "/users/authentication",
  USER_REFRESH_VERIFY_EMAIL: "/users/authentication/passwords",
  ADMIN_LOGIN_API: "/users/authentication",
  RESET_PASSWORD: "/users/authentication/passwords",

  REFRESH_TOKEN: "/users/authentication",
};
