import axios from "axios";
// import { useTranslation } from "react-i18next";
// const { t } = useTranslation();
import swal from "sweetalert";
import { logout } from "@Modules/Authenticate/helpers";

/**
 * Get the variables by current environment
 */
import ENV_VARS from '../config/environments'
const NODE_ENV = process.env.REACT_APP_ENV
const { API_URL } = ENV_VARS[NODE_ENV] || {}

if (!API_URL) {
	console.error('THE ENVIRONMENT!')
}

/** Initialize the Axios instance */
const axiosCreate = axios.create({
	baseURL: API_URL,
});

// axiosCreate
//   .request({
//     baseURL: BASE_URL_API
//     // You can add your headers here
//   })
//   .catch(function (error) {
//     if (!error.response) {
//       // network error
//       // console.error('network error');
//       // console.error(error);
//     } else {
//       // console.error('Error status');
//       // console.error(error.response.status);
//       // http status code
//       const code = error.response.status
//       // response data
//       const response = error.response.data
//       console.log(code, response)
//     }
//   })

axiosCreate.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const originalRequest = error.config;
		if (error?.response?.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			swal("Oops", `Token Expire`, "error", {
				closeOnEsc: false,
				closeOnClickOutside: false,
			}).then(() => {
				localStorage.removeItem("accessToken");
				logout();
				window.location.replace("/");
			});
			return;
		}
		if (error.response && error.response.data) {
			return Promise.reject(error.response.data);
		}

		return Promise.reject(error.message);
	},
);

axiosCreate.interceptors.request.use((request) => {
	return request;
});

export default axiosCreate;
